import React, { useState, createContext, useMemo } from 'react';

const FormContext = createContext({});

const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  const formContextProviderValue = useMemo(
    () => ({ formData, setFormData }),
    [formData, setFormData],
  );
  return (
    <FormContext.Provider value={formContextProviderValue}>
      {children}
    </FormContext.Provider>
  );
};

export { FormContext, FormProvider };
